.profile-sub-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 14px #0000001a;
  border-radius: 15px;
  padding: 15px;
  width: 100%;
}
.profile-section {
  .heading {
    color: #202020;
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    float: left; 
    margin-bottom: 20px;
    width: 100%;
  }
  .upload-photo {   
    margin: 0px 35px 0 40px;
    height: 140px;
    border-right: 2px solid #CCCCCC;
    .avtar-alignment {
      margin: 2px 30px 0 -25px;
    }
    .cust-id {
      margin: 5px 0 0 -50px;
    }
    .change-profile {
      color: #fe077c;
      font-size: 12px;
      position: absolute;
      width: 100%; 
      line-height: 15px;
      margin: 7px 0 0 -75px;
    }
    .file-upload{
      opacity: 0;
      position: absolute;
      width: 80px;
      margin: 9px 0 0 -65px;
      cursor: pointer;
    }
  }
  .form-input {
    text-align: left;
    .text-label {
      text-align: left;
      font-size: 12px;
      color: #4e5d78;
      font-weight: 600;
      margin-bottom: 5px;
    }
    input:disabled {
      background-color: #EEEEEE;
    }
    .text-input { 
      height: 40px;
      border: 1px solid #DDDDDD;
      color: #888888;
      width: 100%;
      border-radius: 4px; 
      padding: 10px 12px;
      font-size: 14px;
      &:focus{
        outline: none;
      }
      &::placeholder{
        color: #B0B7C3;
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }
  .btn-card {
    text-align: left;
    margin-top: 20px;
    .cancel-btn {
      width: 112px;
      height: 34px;
      background: #cccccc;
      border-radius: 24px;
      color: #fff;
      border: none;
    }
    .update-btn {
      width: 112px;
      height: 34px;
      background: #fe077c;
      border-radius: 24px;
      color: #fff;
      border: none;
      margin-left: 20px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .profile-section {
    .upload-photo {
      border-right: none;
    }
  }
}
@media screen and (max-width: 600px) {
  .form-input {
    .text-input {
      width: 200px !important;
    }
  }
}
