//add customer modal
.add-customer {
  display: grid;
  justify-content: center;
  .event-modal-label {
    font-size: 12px;
    color: #4e5d78;
  }
  .event-modal-detail {
    width: 375px;
    height: 40px;
    padding: 10px 12px;
    background: #ffffff;
    font-size: 13px;
    border: 1px solid #E1E4E8;
    border-radius: 5px;
    .form-input {
      border: none;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
    }
  }
}
.view-customer {
  .customer-profile {
    cursor: default;
  }
  .table-row-light td {
    color: #202020 !important;
  }
  .table-row-light td .highlight-text {
    color: #FE077C !important;
  }
  .data-table .ant-table-container .ant-table-header .ant-table-thead tr th:nth-child(7){
    padding-left: 1.5rem;
  }
  select option:after {
    width: 15px;
    height: 15px;
    background-color: red;
  }
  .ant-table-wrapper {
    .ant-table table {
      thead {  
        th:first-of-type {
          width: 140px;
          padding-left: 20px;
        }
        th:nth-child(2){
          width: 150px;
        }
        th:nth-child(3){
          width: 160px;
        }
        th:nth-child(4){
          width: 160px;
        }
        th:nth-child(5){
          width: 160px;
        }
        th:nth-child(6){
          width: 175px;
        } 
        th:last-of-type {
          width: 150px;
          padding-left: 35px;
        }
      } 
      tbody tr {
        td:first-of-type { 
          padding-left: 17px;
        }
      }
    }
  }
}
.move-back {
  svg {
    height: 28px;
    width: 28px;
    path {
      fill: #fe077c !important;
    }
  }
}
.edit-color {
  .edit-img {
    width: 14px;
    margin: -2px 4px 0 3px;
  }
}
.delete-color {
  .delete-img {
    width: 10px;
    margin: -2px 5px 0 3px;
  }
}
.back-btn {
  width: 66px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 5px #00000029;
  border-radius: 4px;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #fe077c;
  padding: 0;
}
.btn-wrap { 
  .save-btn {
    background: #fe077c;
    color: #ffffff;
    width: 70px;
    height: 32px;
    border: none;
    border-radius: 30px;
    margin-right: 15px;
    font-size: 14px;
  }
  .cancel-btn {
    background: transparent;
    color: #aeaeae;
    width: 70px;
    height: 32px;
    border: 2px solid #CCCCCC;
    border-radius: 30px;
    font-size: 14px;
  }
  .delete-btn {
    background: transparent;
    color: #f4463f;
    width: 75px;
    height: 32px;
    border: 2px solid #f4463f;
    border-radius: 30px;
    font-size: 14px;
  }
  .edit-outline-btn {
    background: transparent;
    color: #fe077c;
    width: 70px;
    height: 32px;
    border: 2px solid #fe077c;
    border-radius: 30px;
    font-size: 14px;
    margin-right: 15px;
  }
  @media screen and (max-width: 700px) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.radio-label {
  font-size: 13px !important;
  margin-right: 20px !important;
  font-weight: 500;
}
.form-check-input {
  width: 13px;
  height: 13px;
  margin-right: 3px;
  &:checked {
    background-color: #fe077c !important;
    border: 0.5px solid #cccccc !important;
    border-color: #cccccc !important;
  }
  &:focus {
    box-shadow: none;
    border: 0.5px solid #cccccc;
  }
}
.customer-profile {
  color: #fe077c;
  font-size: 12px;
  cursor: pointer;
}
.acc-dropdown {
  position: absolute;
  top: 28px;
  right: 11px;
  svg {
    height: 14px;
    width: 14px;
  }
}
.customer-status {
  width: 100%;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 4px;
  appearance: none;
  padding-left: 5px;
  &:focus{
    outline: none;
  }
}
.status{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #4e5d78;
}
.status-dropdown{
  position: absolute;
  right: 5px;
  top: 13px;
} 