.container-chk {
    display: block;
    position: relative;
    padding-left: 10px; 
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .label{
      margin-left: 5px;
      color: #202020;
      font-size: 14px;
    }
  }
  
  /* Hide the browser's default checkbox */
  .container-chk .pink-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input[type='checkbox'] {
    opacity: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 7px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #d4d4d4;
  }
  
  /* On mouse-over, add a grey background color */
  .container-chk:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-chk input:checked ~ .checkmark {
    background-color: #FE077C;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-chk input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-chk .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .status{
    background: red;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    z-index: 10000;
  }